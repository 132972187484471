function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ChartAvailability, ChartAvailabilityProps } from '@landfolk/shared/components/Chart/Availability';
import { ChartDemand, ChartDemandProps } from '@landfolk/shared/components/Chart/Demand';
import { ChartEarnings, ChartEarningsProps } from '@landfolk/shared/components/Chart/Earnings';
import { ChartGauge } from '@landfolk/shared/components/Chart/Gauge';
import { ChartLegend, ChartLegendDataEntry } from '@landfolk/shared/components/Chart/Legend';
import { ChartRadialBar } from '@landfolk/shared/components/Chart/RadialBar';
import { ChartViews, ChartViewsProps } from '@landfolk/shared/components/Chart/Views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1nh4lbw",
  styles: "max-width:16rem"
} : {
  name: "1c77i26-tooltipContent",
  styles: "max-width:16rem;label:tooltipContent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "cysvly",
  styles: "font-size:0.625rem;line-height:1rem"
} : {
  name: "1ps65k7-tooltipContent",
  styles: "font-size:0.625rem;line-height:1rem;label:tooltipContent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "upembd",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;}"
} : {
  name: "ym2f8x-ChartsExample",
  styles: "margin-bottom:2rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:3rem;line-height:3rem;@media (max-width: 63.999em){font-size:1.5rem;line-height:1.5rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "v30hbo",
  styles: "display:flex;max-width:65ch;flex-direction:column"
} : {
  name: "1e7uayh-ChartsExample",
  styles: "display:flex;max-width:65ch;flex-direction:column;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1l9fbg2-ChartsExample",
  styles: "margin-bottom:2rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "q1nai5",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){margin-bottom:0.5rem;font-size:1rem;line-height:1.5rem;}"
} : {
  name: "ori7ot-ChartsExample",
  styles: "margin-bottom:1rem;font-size:1.5rem;line-height:1.5rem;font-weight:600;@media (max-width: 63.999em){margin-bottom:0.5rem;font-size:1rem;line-height:1.5rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "1tbgl51",
  styles: "margin-bottom:2rem;margin-left:1.5rem;list-style-type:disc"
} : {
  name: "ev90yr-ChartsExample",
  styles: "margin-bottom:2rem;margin-left:1.5rem;list-style-type:disc;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "12qft46",
  styles: "display:grid;gap:4rem"
} : {
  name: "1tb1b5d-ChartsExample",
  styles: "display:grid;gap:4rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "6igfwr",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "ksgmlb-ChartsExample",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "6igfwr",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "ksgmlb-ChartsExample",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1yp7290",
  styles: "margin-bottom:2rem"
} : {
  name: "1l9fbg2-ChartsExample",
  styles: "margin-bottom:2rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "9lq9o8",
  styles: "margin-bottom:1rem;display:flex;flex-wrap:wrap;align-items:center;gap:1rem;text-align:center"
} : {
  name: "8v5eng-ChartsExample",
  styles: "margin-bottom:1rem;display:flex;flex-wrap:wrap;align-items:center;gap:1rem;text-align:center;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "9lq9o8",
  styles: "margin-bottom:1rem;display:flex;flex-wrap:wrap;align-items:center;gap:1rem;text-align:center"
} : {
  name: "8v5eng-ChartsExample",
  styles: "margin-bottom:1rem;display:flex;flex-wrap:wrap;align-items:center;gap:1rem;text-align:center;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "6igfwr",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem"
} : {
  name: "ksgmlb-ChartsExample",
  styles: "margin-bottom:2rem;font-size:1.5rem;line-height:1.5rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "r9s25c",
  styles: "display:flex;flex-wrap:wrap;column-gap:1rem;row-gap:2rem"
} : {
  name: "15u03yo-ChartsExample",
  styles: "display:flex;flex-wrap:wrap;column-gap:1rem;row-gap:2rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "3oh4gq",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;}"
} : {
  name: "1g9a1rj-ChartsExample",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "1jqjl7n",
  styles: "margin-bottom:1rem;font-weight:600"
} : {
  name: "1n55c2x-ChartsExample",
  styles: "margin-bottom:1rem;font-weight:600;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "kr58t4",
  styles: "position:relative;flex-shrink:1;flex-grow:1"
} : {
  name: "1kcyvhk-ChartsExample",
  styles: "position:relative;flex-shrink:1;flex-grow:1;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "3oh4gq",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;}"
} : {
  name: "1g9a1rj-ChartsExample",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "1jqjl7n",
  styles: "margin-bottom:1rem;font-weight:600"
} : {
  name: "1n55c2x-ChartsExample",
  styles: "margin-bottom:1rem;font-weight:600;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "kr58t4",
  styles: "position:relative;flex-shrink:1;flex-grow:1"
} : {
  name: "1kcyvhk-ChartsExample",
  styles: "position:relative;flex-shrink:1;flex-grow:1;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "3oh4gq",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;}"
} : {
  name: "1g9a1rj-ChartsExample",
  styles: "display:flex;height:17.75rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "1jqjl7n",
  styles: "margin-bottom:1rem;font-weight:600"
} : {
  name: "1n55c2x-ChartsExample",
  styles: "margin-bottom:1rem;font-weight:600;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "kr58t4",
  styles: "position:relative;flex-shrink:1;flex-grow:1"
} : {
  name: "1kcyvhk-ChartsExample",
  styles: "position:relative;flex-shrink:1;flex-grow:1;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "8dk3vg",
  styles: "margin-bottom:1rem"
} : {
  name: "15xggmk-ChartsExample",
  styles: "margin-bottom:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref31 = process.env.NODE_ENV === "production" ? {
  name: "8i86sw",
  styles: "display:flex;height:17rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;}"
} : {
  name: "e6kmot-ChartsExample",
  styles: "display:flex;height:17rem;width:25rem;flex-direction:column;border-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));--tw-shadow:0.4px 0.8px 2px 0 rgba(2,32,33,0.04), 0.8px 1.6px 4px 0 rgba(2,32,33,0.04), 1.6px 3.2px 8px 0 rgba(2,32,33,0.04), 3.2px 6.4px 16px 0 rgba(2,32,33,0.04), 0 0 0 1px rgba(2,32,33,0.1);--tw-shadow-colored:0.4px 0.8px 2px 0 var(--tw-shadow-color), 0.8px 1.6px 4px 0 var(--tw-shadow-color), 1.6px 3.2px 8px 0 var(--tw-shadow-color), 3.2px 6.4px 16px 0 var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);@media (min-width: 64em){padding:1.5rem;}@media (max-width: 63.999em){padding:1rem;};label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref32 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "2z3n3i-ChartsExample",
  styles: "font-weight:600;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref33 = process.env.NODE_ENV === "production" ? {
  name: "cysvly",
  styles: "font-size:0.625rem;line-height:1rem"
} : {
  name: "19pm92i-ChartsExample",
  styles: "font-size:0.625rem;line-height:1rem;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref34 = process.env.NODE_ENV === "production" ? {
  name: "1ir7acg",
  styles: "position:relative;margin-top:1rem;flex-shrink:1;flex-grow:1"
} : {
  name: "wwsiz4-ChartsExample",
  styles: "position:relative;margin-top:1rem;flex-shrink:1;flex-grow:1;label:ChartsExample;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function ChartsExample() {
  const legendData = new Map<string, ChartLegendDataEntry>();
  legendData.set('dot', {
    name: 'Dot',
    color: "#366752"
  });
  legendData.set('tooltip', {
    name: 'With tooltip',
    color: "#366752",
    tooltipContent: _jsx("div", {
      css: _ref,
      children: _jsx("p", {
        css: _ref2,
        children: "Tooltip content"
      })
    })
  });
  legendData.set('dotCombined', {
    name: 'Combined dots',
    color: "#366752",
    secondaryColor: "#8C3216"
  });
  legendData.set('line', {
    name: 'Line',
    type: 'line',
    color: "#C55417"
  });
  legendData.set('lineCombined', {
    name: 'Combined lines',
    type: 'line',
    color: "#C55417",
    secondaryColor: "#86AEB2"
  });
  legendData.set('lineDashed', {
    name: 'Dashed line',
    type: 'line-dash',
    color: "#67797A"
  });
  legendData.set('lineDashedCombined', {
    name: 'Combined dashed lines',
    type: 'line-dash',
    color: "#67797A",
    secondaryColor: "#86AEB2"
  });
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref3,
      children: "Charts"
    }), _jsxs("div", {
      css: _ref4,
      children: [_jsx("p", {
        css: _ref5,
        children: "Charts help users understand numbers in a visual way and make data-heavy pages or information less heavy. They use visualisation, animation as well as colour to communicate data."
      }), _jsx("h3", {
        css: _ref6,
        children: "Best practices"
      }), _jsxs("ul", {
        css: _ref7,
        children: [_jsx("li", {
          children: "Use positive or neutral colours, so as not to judge the data"
        }), _jsx("li", {
          children: "Use a high colour contrast if the chart uses several equal colours to make the charts accessible"
        }), _jsx("li", {
          children: "Add tooltips or a short description to explain the charts"
        })]
      })]
    }), _jsxs("div", {
      css: _ref8,
      children: [_jsxs("section", {
        children: [_jsx("h2", {
          css: _ref9,
          children: "Legend"
        }), _jsx(ChartLegend, {
          data: legendData
        })]
      }), _jsxs("section", {
        children: [_jsx("h2", {
          css: _ref10,
          children: "Basic"
        }), _jsxs("div", {
          css: _ref11,
          children: [_jsx("p", {
            css: _ref12,
            children: "Radial bar chart"
          }), _jsxs("div", {
            css: _ref13,
            children: [_jsx(ChartRadialBar, {
              value: 100,
              max: 100
            }), _jsx(ChartRadialBar, {
              value: 6,
              max: 10,
              animationBegin: 200
            }), _jsx(ChartRadialBar, {
              value: 5,
              max: 10,
              animationBegin: 400
            }), _jsx(ChartRadialBar, {
              value: 2.5,
              max: 10,
              animationBegin: 600
            }), _jsx(ChartRadialBar, {
              value: 5,
              max: 100,
              animationBegin: 800
            }), _jsx(ChartRadialBar, {
              value: 1,
              max: 100
            }), _jsx(ChartRadialBar, {
              value: 0,
              max: 100
            }), _jsx(ChartRadialBar, {})]
          })]
        }), _jsxs("div", {
          children: [_jsx("p", {
            css: _ref14,
            children: "Gauge chart"
          }), _jsxs("div", {
            css: _ref15,
            children: [_jsx(ChartGauge, {
              value: 100,
              max: 100,
              animationBegin: 400
            }), _jsx(ChartGauge, {
              value: 6,
              max: 10,
              animationBegin: 600
            }), _jsx(ChartGauge, {
              value: 5,
              max: 10,
              animationBegin: 800
            }), _jsx(ChartGauge, {
              value: 2.5,
              max: 10,
              animationBegin: 1000
            }), _jsx(ChartGauge, {
              value: 5,
              max: 100,
              animationBegin: 1200
            }), _jsx(ChartGauge, {
              value: 1,
              max: 100,
              animationBegin: 1400,
              arcDegrees: 180
            }), _jsx(ChartGauge, {
              value: 0,
              max: 100,
              arcDegrees: 120
            }), _jsx(ChartGauge, {})]
          })]
        })]
      }), _jsxs("section", {
        children: [_jsx("h2", {
          css: _ref16,
          children: "Use-case specific"
        }), _jsxs("div", {
          css: _ref17,
          children: [_jsxs("div", {
            children: [_jsx("p", {
              css: _ref18,
              children: "Availability chart"
            }), _jsxs("div", {
              css: _ref19,
              children: [_jsx("p", {
                css: _ref20,
                children: "Availability"
              }), _jsx("div", {
                css: _ref21,
                children: _jsx(ChartAvailability, {
                  data: chartAvailabilityData
                })
              })]
            })]
          }), _jsxs("div", {
            children: [_jsx("p", {
              css: _ref22,
              children: "Earnings chart"
            }), _jsxs("div", {
              css: _ref23,
              children: [_jsx("p", {
                css: _ref24,
                children: "Earnings"
              }), _jsx("div", {
                css: _ref25,
                children: _jsx(ChartEarnings, {
                  data: chartEarningsData
                })
              })]
            })]
          }), _jsxs("div", {
            children: [_jsx("p", {
              css: _ref26,
              children: "Views chart"
            }), _jsxs("div", {
              css: _ref27,
              children: [_jsx("p", {
                css: _ref28,
                children: "Views"
              }), _jsx("div", {
                css: _ref29,
                children: _jsx(ChartViews, {
                  listingPublishedAt: "2024-01-01",
                  data: chartViewsData
                })
              })]
            })]
          }), _jsxs("div", {
            children: [_jsx("p", {
              css: _ref30,
              children: "Demand chart"
            }), _jsxs("div", {
              css: _ref31,
              children: [_jsx("p", {
                css: _ref32,
                children: "Guests are currently booking"
              }), _jsx("p", {
                css: _ref33,
                children: "Right now guests are booking these periods"
              }), _jsx("div", {
                css: _ref34,
                children: _jsx(ChartDemand, {
                  calendarLink: "",
                  data: chartDemandData
                })
              })]
            })]
          })]
        })]
      })]
    })]
  });
}
const chartAvailabilityData: ChartAvailabilityProps['data'] = {
  months: [{
    availabilityRate: 0,
    availableNights: 0,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0.08,
    date: '2024-01-01',
    totalNights: 31
  }, {
    availabilityRate: 0.3448275862068966,
    availableNights: 10,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0.22,
    date: '2024-02-01',
    totalNights: 29
  }, {
    availabilityRate: 0.3225806451612903,
    availableNights: 10,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0.18,
    date: '2024-03-01',
    totalNights: 31
  }, {
    availabilityRate: 1,
    availableNights: 30,
    currentDemandCategory: 'MEDIUM',
    currentDemandRate: 0.41,
    date: '2024-04-01',
    totalNights: 30
  }, {
    availabilityRate: 0.7741935483870968,
    availableNights: 24,
    currentDemandCategory: 'MEDIUM',
    currentDemandRate: 0.47,
    date: '2024-05-01',
    totalNights: 31
  }, {
    availabilityRate: 1,
    availableNights: 30,
    currentDemandCategory: 'HIGH',
    currentDemandRate: 0.83,
    date: '2024-06-01',
    totalNights: 30
  }, {
    availabilityRate: 0.8064516129032258,
    availableNights: 25,
    currentDemandCategory: 'HIGH',
    currentDemandRate: 1,
    date: '2024-07-01',
    totalNights: 31
  }, {
    availabilityRate: 0.9032258064516129,
    availableNights: 28,
    currentDemandCategory: 'HIGH',
    currentDemandRate: 0.79,
    date: '2024-08-01',
    totalNights: 31
  }, {
    availabilityRate: 1,
    availableNights: 30,
    currentDemandCategory: 'MEDIUM',
    currentDemandRate: 0.13,
    date: '2024-09-01',
    totalNights: 30
  }, {
    availabilityRate: 1,
    availableNights: 31,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0.33,
    date: '2024-10-01',
    totalNights: 31
  }, {
    availabilityRate: 1,
    availableNights: 30,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0,
    date: '2024-11-01',
    totalNights: 30
  }, {
    availabilityRate: 0.6129032258064516,
    availableNights: 19,
    currentDemandCategory: 'LOW',
    currentDemandRate: 0.29,
    date: '2024-12-01',
    totalNights: 31
  }]
};
const chartEarningsData: ChartEarningsProps['data'] = {
  months: [{
    date: '2024-01-01',
    total: {
      majorAmount: 2923,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 2923,
      currency: 'EUR'
    }
  }, {
    date: '2024-02-01',
    total: {
      majorAmount: 2501,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 2501,
      currency: 'EUR'
    }
  }, {
    date: '2024-03-01',
    total: {
      majorAmount: 6830,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 6830,
      currency: 'EUR'
    }
  }, {
    date: '2024-04-01',
    total: {
      majorAmount: 3532,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 3532,
      currency: 'EUR'
    }
  }, {
    date: '2024-05-01',
    total: {
      majorAmount: 6782,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 3217,
      currency: 'EUR'
    }
  }, {
    date: '2024-06-01',
    total: {
      majorAmount: 8211,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-07-01',
    total: {
      majorAmount: 10213,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-08-01',
    total: {
      majorAmount: 8213,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-09-01',
    total: {
      majorAmount: 5987,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-10-01',
    total: {
      majorAmount: 1897,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-11-01',
    total: {
      majorAmount: 0,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }, {
    date: '2024-12-01',
    total: {
      majorAmount: 913,
      currency: 'EUR'
    },
    totalPaidOut: {
      majorAmount: 0,
      currency: 'EUR'
    }
  }],
  total: {
    majorAmount: 30799,
    currency: 'EUR'
  }
};
const chartViewsData: ChartViewsProps['data'] = {
  months: [{
    date: '2024-01-01',
    views: 621
  }, {
    date: '2024-02-01',
    views: 354
  }, {
    date: '2024-03-01',
    views: 227
  }, {
    date: '2024-04-01',
    views: 274
  }, {
    date: '2024-05-01',
    views: 235
  }, {
    date: '2024-06-01',
    views: 282
  }, {
    date: '2024-07-01',
    views: 374
  }, {
    date: '2024-08-01',
    views: 282
  }, {
    date: '2024-09-01',
    views: 423
  }, {
    date: '2024-10-01',
    views: 261
  }, {
    date: '2024-11-01',
    views: 231
  }, {
    date: '2024-12-01',
    views: 333
  }, {
    date: '2025-01-01',
    views: 215
  }]
};
const chartDemandData: ChartDemandProps['data'] = {
  periods: [{
    rate: 0.495,
    label: 'Summer 2024',
    interval: {
      first: '2024-06-01',
      last: '2024-08-31'
    }
  }, {
    rate: 0.25,
    label: 'Winter 2024',
    interval: {
      first: '2024-12-01',
      last: '2025-02-28'
    }
  }, {
    rate: 0.15,
    label: 'Easter 2024',
    interval: {
      first: '2024-04-01',
      last: '2024-04-30'
    }
  }, {
    rate: 0.1,
    label: 'Other'
  }]
};