import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export const pastAreaPatternId = 'pastAreaPattern';
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1u7jxo2",
  styles: "stroke:#CCD2D2;@media (max-resolution: 1.5x){stroke:#E6E9E9;stroke-dasharray:none;}"
} : {
  name: "lqwlvh-PastAreaPattern",
  styles: "stroke:#CCD2D2;@media (max-resolution: 1.5x){stroke:#E6E9E9;stroke-dasharray:none;};label:PastAreaPattern;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1u7jxo2",
  styles: "stroke:#CCD2D2;@media (max-resolution: 1.5x){stroke:#E6E9E9;stroke-dasharray:none;}"
} : {
  name: "lqwlvh-PastAreaPattern",
  styles: "stroke:#CCD2D2;@media (max-resolution: 1.5x){stroke:#E6E9E9;stroke-dasharray:none;};label:PastAreaPattern;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const PastAreaPattern = () => {
  return _jsxs("pattern", {
    id: pastAreaPatternId,
    patternUnits: "userSpaceOnUse",
    width: "10",
    height: "10",
    children: [_jsx("line", {
      x1: "20.3536",
      y1: "-9.64645",
      x2: "0.353554",
      y2: "10.3536",
      strokeDasharray: "1 1",
      css: _ref
    }), _jsx("line", {
      x1: "10.3536",
      y1: "-9.64645",
      x2: "-9.64645",
      y2: "10.3536",
      stroke: "#E6E9E9",
      strokeDasharray: "1 1",
      css: _ref2
    })]
  });
};