/* eslint-disable @typescript-eslint/no-var-requires */
const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')
const tokens = require('@landfolk/tokens/__generated__/tailwind.tokens.json')

/** @type {import('tailwindcss').Config} */
module.exports = {
  theme: {
    extend: {
      fontFamily: {
        sans: ['var(--font-sans)', ...defaultTheme.fontFamily.sans],
        serif: ['var(--font-serif)', ...defaultTheme.fontFamily.serif],
        handwritten: [
          'var(--font-handwritten)',
          ...defaultTheme.fontFamily.sans,
        ],
        mono: ['var(--font-mono)', ...defaultTheme.fontFamily.mono],
      },
      fontWeight: {
        bold: 600,
      },
      width: {
        'page-xl': '86em',
        page: '60em',
        0.75: '0.75rem',
        1: '1rem',
        1.5: '1.5rem',
        2: '2rem',
        2.5: '3rem',
        3: '4rem',
        3.5: '6rem',
        4: '8rem',
        4.5: '12rem',
        5: '16rem',
        6: '32rem',
        available: 'fill-available',
      },
      height: {
        0.75: '0.75rem',
        1: '1rem',
        1.5: '1.5rem',
        2: '2rem',
        2.5: '3rem',
        3: '4rem',
        3.5: '6rem',
        4: '8rem',
        4.5: '12rem',
        5: '16rem',
        6: '32rem',
        available: 'fill-available',
      },
      minWidth: {
        0.75: '0.75rem',
        1: '1rem',
        1.5: '1.5rem',
        2: '2rem',
        2.5: '3rem',
        3: '4rem',
        4: '8rem',
        5: '16rem',
        6: '32rem',
        7: '48rem',
        8: '64rem',
        9: '96rem',
      },
      maxWidth: {
        'page-xl': '86em',
        'page-2xl': '96em',
        page: '60em',
        0.75: '0.75rem',
        1: '1rem',
        1.5: '1.5rem',
        2: '2rem',
        2.5: '3rem',
        3: '4rem',
        4: '8rem',
        5: '16rem',
        6: '32rem',
        7: '48rem',
        8: '64rem',
        9: '96rem',
      },
      maxHeight: {
        0.75: '0.75rem',
        1: '1rem',
        1.5: '1.5rem',
        2: '2rem',
        2.5: '3rem',
        3: '4rem',
        4: '8rem',
        5: '16rem',
        6: '32rem',
        available: 'fill-available',
      },
      zIndex: {
        floating: 70,
        toolbar: 75,
        navigation: 80,
        overlay: 90,
      },
      boxShadow: tokens.boxShadow,
      listStyleType: {
        line: '"—"',
      },
      animation: {
        pulse: 'pulse 2s ease-in-out both alternate infinite',
        shake: 'shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both',
        'slide-top': 'slide-top 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'slide-bottom': 'slide-bottom 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'slide-left': 'slide-left 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'slide-right': 'slide-right 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'zoom-in': 'zoom-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards',
        'fade-in': 'fade-in 0.4s cubic-bezier(0, 0, 0.2, 1) forwards',
        'fade-out': 'fade-out 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'dialog-in': 'dialog-in 0.3s cubic-bezier(0, 0, 0.2, 1) forwards',
        'dialog-out': 'dialog-out 0.2s cubic-bezier(0, 0, 0.2, 1) forwards',
      },
      keyframes: {
        pulse: {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.5 },
        },
        shake: {
          '10%, 90%': { transform: 'translate3d(-1px, 0, 0)' },
          '20%, 80%': { transform: 'translate3d(2px, 0, 0)' },
          '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)' },
          '40%, 60%': { transform: 'translate3d(4px, 0, 0)' },
        },
        'slide-top': {
          '0%': { transform: 'translateY(-10px)', opacity: '0' },
          '30%': { opacity: '1' },
          '100%': { transform: 'translateY(0px)', opacity: '1' },
        },
        'slide-bottom': {
          '0%': { transform: 'translateY(10px)', opacity: '0' },
          '30%': { opacity: '1' },
          '100%': { transform: 'translateY(0px)', opacity: '1' },
        },
        'slide-left': {
          '0%': { transform: 'translateX(-10px)', opacity: '0' },
          '30%': { opacity: '1' },
          '100%': { transform: 'translateX(0px)', opacity: '1' },
        },
        'slide-right': {
          '0%': { transform: 'translateX(10px)', opacity: '0' },
          '30%': { opacity: '1' },
          '100%': { transform: 'translateX(0px)', opacity: '1' },
        },
        'zoom-in': {
          '0%': { transform: 'scale(0.7)', opacity: '0' },
          '100%': { transform: 'scale(1)', opacity: '1' },
        },
        'fade-in': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
        'fade-out': {
          '0%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        'dialog-in': {
          '0%': {
            opacity: '0',
            transform: 'translateY(1rem)',
          },
          '100%': {
            opacity: '1',
            transform: 'translateY(0rem)',
          },
        },
        'dialog-out': {
          '0%': {
            opacity: '1',
            transform: 'translateY(0rem)',
          },
          '100%': {
            opacity: '0',
            transform: 'translateY(-1rem)',
          },
        },
      },
      transitionTimingFunction: {
        'in-expo': 'cubic-bezier(0.7, 0, 0.84, 0)',
        'out-expo': 'cubic-bezier(0.16, 1, 0.3, 1)',
        'in-out-expo': 'cubic-bezier(0.87, 0, 0.13, 1)',
        'in-smooth': 'cubic-bezier(0.7, 0, 0.55, 1)',
        'out-smooth': 'cubic-bezier(0.25, 0, 0.25, 1)',
        'in-strong': 'cubic-bezier(1, 0, 0.65, 0.1)',
        'out-strong': 'cubic-bezier(0.35, 0.9, 0, 1)',
      },
      outlineOffset: {
        0: '0rem',
        1: '0.125rem',
        2: '0.25rem',
        3: '0.375rem',
      },
    },
    colors: {
      ...tokens.colors,
      transparent: 'transparent',
      current: 'currentColor',
    },
    screens: {
      // Extra small, below 320px
      xs: { max: '19.99em' },
      // Small, below 640px
      sm: { max: '39.999em' },
      // Not small, above 640px
      ns: { min: '40em' },
      // Medium, above 640px + below 1024px
      md: { min: '40em', max: '63.999em' },
      // Large, above 1024px
      lg: { min: '64em' },
      // Not large, below 1024px
      nl: { max: '63.999em' },
      // Extra large, above 1280px
      xl: { min: '80em' },
      // Medium large, above 1024px + below 1280px
      ml: { min: '64em', max: '79.999em' },
      portrait: { raw: '(orientation: portrait)' },
      landscape: { raw: '(orientation: landscape)' },
      'can-hover': { raw: '(any-hover: hover)' }, // one or more available input mechanism(s) can hover over elements with ease
      'cant-hover': { raw: '(any-hover: none)' }, // one or more available input mechanism(s) cannot hover or there are no pointing input mechanisms
      lodpi: { raw: '(max-resolution: 1.5x)' },
      hidpi: { raw: '(min-resolution: 1.5x)' },
    },
    fontSize: {
      f1: ['4rem', '4rem'],
      f2: ['3rem', '3rem'],
      'f2.5': ['2rem', '2rem'],
      f3: ['1.5rem', '1.5rem'],
      f4: ['1.25rem', '1.5rem'],
      f5: ['1rem', '1.5rem'],
      f6: '0.875rem', // Not in use
      f7: ['0.75rem', '1rem'],
      f8: ['0.625rem', '1rem'],
      0: 0,
    },
    spacing: {
      ...tokens.spacing,
      'page-padding': 'var(--tw-page-padding)', // Special case for web. Variable is defined in `Layout` component and different at different screen sizes
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
    require('tailwind-scrollbar-hide'),
    plugin(({ addUtilities, addVariant }) => {
      addUtilities({
        '.inset-center': {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
        '.outline-offset-0': { outlineOffset: '0rem' },
        '.outline-offset-1': { outlineOffset: '0.125rem' },
        '.outline-offset-2': { outlineOffset: '0.25rem' },
        '.outline-offset-3': { outlineOffset: '0.375rem' },
        '::selection': { background: '#366752', color: '#ffffff' },
        b: { fontWeight: 600 },
        strong: { fontWeight: 600 },
      })
      addVariant('not-hover', ['&:not(:hover)'])
      addVariant('not-focus', ['&:not(:focus)'])
      addVariant('not-hocus', ['&:not(:hover):not(:focus)'])
    }),
  ],
}
