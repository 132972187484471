import { ImageType } from '../../types/graphql';
import { Participants } from '../Attendees';
import type { MoneyProps } from '../FormattedMoney';
export type ListingDateConfiguration = {
  available?: boolean;
  date?: DateTimestamp;
  price?: MoneyProps;
  standardPrice?: MoneyProps;
  hasCustomPrice?: boolean;
  customAvailability?: boolean;
  minNights?: number;
  groupedDates: {
    first: DateTimestamp;
    last: DateTimestamp;
  };
  instantBookable?: boolean;
};
export interface NightConfig extends ListingDateConfiguration {
  position: 'START' | 'MIDDLE' | 'END';
  date: DateTimestamp;
  standardMinNights?: number;
  hasCustomMinNights?: boolean;
  duration: number;
  // the duration used when the config is used as a prevConfig
  prevDuration?: number;
  nightsLeft: number;
  numberOfNights?: number;
}
export interface EventConfig {
  date: DateTimestamp;
  duration: number;
  nightsLeft: number;
  position: 'START' | 'MIDDLE' | 'END';
  trackOrder: number;
  event: CalendarEvent;
  maxMinNights?: number;
  standardPreparationDays?: number;
  standardMaxLeadMonths?: number;
  standardAdvanceNoticeDays?: number;
}
export interface BookingEventConfig extends Omit<EventConfig, 'event'> {
  event: CalendarBookingEvent;
}

/**
 * @deprecated
 */
export interface ExternalEventConfig extends Omit<EventConfig, 'event'> {
  event: ExternalEvent;
}
export type DateTimestamp = string;
export type DateTimestampInterval = string[];
export type DateTimestampRange = {
  first: DateTimestamp;
  last: DateTimestamp;
};
export type CalendarConfig = Map<DateTimestamp, (EventConfig | NightConfig)[] | undefined>;
export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Day = number;
export type WeekNumber = number;
export type Week = [Day, Day, Day, Day, Day, Day, Day];
export type Weeks = Week[];
export type WeekNumbers = WeekNumber[];
export enum BookingState {
  Checkout = 'CHECKOUT',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Withdrawn = 'WITHDRAWN',
}
export type BookingInvoice = {
  __typename?: 'HostInvoice';
  total?: MoneyProps;
};
export type BookingBooker = {
  __typename?: 'Booker' | 'AdminBooker';
  firstName?: string;
  lastName?: string;
  city: string;
  country: string;
  phoneNumber?: {
    international: string;
  };
  photo?: ImageType;
};
export type Booking = {
  __typename?: 'Booking' | 'BookingAsHost' | 'AdminBooking';
  checkinOn: string;
  checkoutOn: string;
  checkinTime?: string;
  checkoutTime?: string;
  id: string;
  numberOfNights: number;
  reference: string;
  shortId: string;
  state: BookingState;
  invoice?: BookingInvoice;
  booker?: BookingBooker;
  participants: Participants;
};
export type ExternalEvent = {
  __typename?: 'ExternalListingCalendarEvent';
  dateRange: {
    first?: string;
    last?: string;
  };
  reason: 'EXTERNALLY_BOOKED';
  id: string;
  numberOfNights: number;
  summary: string;
};
export type CalendarEventReason = 'ADVANCE_NOTICE' | 'BLOCKED' | 'BOOKED' | 'CLEANING' | 'EXTERNALLY_BOOKED' | 'MIN_NIGHTS' | 'CLEANING' | 'PREPARATION' | 'UNDECIDED';
export type CalendarEvent = {
  __typename?: 'HostListingCalendarEvent' | 'ExternalListingCalendarEvent';
  dateRange: {
    first?: string;
    last?: string;
  };
  id: string;
  reason: CalendarEventReason;
  ref?: string;
  summary?: string;
  numberOfNights?: number;
  booking?: Booking;
};
export type CalendarBookingEvent = CalendarEvent & {
  reason: 'BOOKED';
  ref: string;
} & Pick<Booking, 'invoice' | 'booker' | 'state'>;
export type CalendarExternalEvent = CalendarEvent & {
  reason: 'EXTERNALLY_BOOKED';
  ref: string;
};
export type ExternalCalendar = {
  __typename?: 'ExternalListingCalendar';
  id: string;
};