import { useEffect, useState } from 'react';

// NOTE: importing { theme } from 'twin.macro' is not used here, because it fails
// during jest tests (this module is included there due to useSelectDates)
import { theme } from '../../config/tailwind.config';
type MinMax = {
  min?: string;
  max?: string;
  raw?: string;
};
export type ScreenTypes = 'sm' | 'nl' | 'ns' | 'md' | 'lg' | 'ml' | 'xl' | '2xl' | 'portrait' | 'landscape' | 'can-hover' | 'cant-hover';
const screens = (theme?.screens as Record<ScreenTypes, MinMax>);
const mediaQueriesForCSS = ({} as Record<ScreenTypes, string>);
const mediaQueriesForJS = ({} as Record<ScreenTypes, string>);
for (const screen in screens) {
  const {
    min,
    max,
    raw
  }: MinMax = screens[(screen as ScreenTypes)];
  const arr = [];
  if (min) {
    arr.push(`(min-width: ${min})`);
  }
  if (max) {
    arr.push(`(max-width: ${max})`);
  }
  if (raw) {
    arr.push(raw);
  }
  mediaQueriesForJS[(screen as ScreenTypes)] = arr.join(' and ');
  mediaQueriesForCSS[(screen as ScreenTypes)] = '@media ' + arr.join(' and ');
}
export const mediaQueries = mediaQueriesForJS;
const memoizedMatch: Partial<Record<ScreenTypes, boolean>> = {
  sm: true
};
export function useScreenSize(screen: ScreenTypes, defaultValue = memoizedMatch[screen] || false) {
  const [matches, setMatches] = useState(defaultValue);
  const query = mediaQueriesForJS[screen];
  useEffect(() => {
    // NOTE: __IS_BROWSER__ fails in jest, so we check for window manually
    if (typeof window === 'undefined') {
      return;
    }
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
      memoizedMatch[screen] = media.matches;
    }
    const listener = () => {
      setMatches(media.matches);
      memoizedMatch[screen] = media.matches;
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, screen]);
  return matches;
}
export default mediaQueriesForCSS;